import React, { useEffect, useState } from "react";
import {
  Button,
  Offcanvas,
  Modal,
  Nav,
  Navbar,
  Row,
  Container,
  Col,
} from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import MenuIcon1 from "../assets/images/icons/menuicon.png";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import HamburgerIcon from "../assets/images/hamburger.png";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import SlotsIcon from "../assets/images/games-icon/slots.png";

import LiveMatch from "../assets/images/games-icon/foot-icon/live-stream.png";
import FoootCasino from "../assets/images/games-icon/foot-icon/poker.png";
import SlotsFoot from "../assets/images/games-icon/foot-icon/slot-machine.png";
import MyBetsFot from "../assets/images/games-icon/foot-icon/betting.png";
import SportsBookFoot from "../assets/images/games-icon/foot-icon/sports.png";

import BonusImg from "../assets/images/bonus.png";
import ReferralImg from "../assets/images/Refferal.png";

const HeaderAfterLogin = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const { showHeaderBalance } = useSelector((state) => state.user);

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const offcanvasDiv = document.getElementsByClassName("offcanvas")[0];

  const handleCloseModal = () => setShowexchangeModal(false);
  const handleShowModal = () => setShowexchangeModal(true);

  const [showToggle, setShowToggle] = useState(true);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = ["sportsbook", "betby", "Slots", "Casino"];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    return () => {};
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  return (
    <header className="header aftrlgn">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center logo-before">
                <div className="leftbar_toggle d-xl-none d-none">
                  <Button
                    variant="primary"
                    onClick={() => setShowLeftbarToggle((s) => !s)}
                  >
                    <img src={MenuIcon1} />
                  </Button>
                </div>
                <div className="logo">
                  <a href="/betby">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>
              </div>

              <Navbar expand="xl" className="d-none d-xl-flex middle-before">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setShowToggle((s) => !s)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    <Nav.Link href="/home" className="logoImg d-xl-none">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </Nav.Link>

                    <Nav.Link
                      href="/sportsbook?bt-path=%2Flive"
                      className={activePage == "sportsbook" ? "active" : ""}
                    >
                      <div className="exchangeflex"></div> Live Betting
                    </Nav.Link>
                    <Nav.Link
                      href="/betby"
                      className={activePage == "betby" ? "active" : ""}
                    >
                      <div className="exchangeflex"></div> Sportsbook
                    </Nav.Link>
                    <Nav.Link
                      href="/Slots"
                      className={activePage == "Slots" ? "active" : ""}
                    >
                      Slots
                    </Nav.Link>

                    <Nav.Link
                      href="/Casino"
                      className={activePage == "Casino" ? "active" : ""}
                    >
                      Casino
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              <div className="headerRight right-before">
                {showHeaderBalance && (
                  <Dropdown align="end" className="me-2">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="tel:{user?.mobileno}">
                        <b>{user?.mobileno}</b>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        <h6>Balance</h6>
                        <span>NOK {numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => setShowBetModal(true)}
                      >
                        <h6>Exposure</h6>
                        <span>NOK {numberWithCommas(exposureAmount || 0)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        <h6>Bonus</h6>
                        <span>NOK {numberWithCommas(wallet?.coins)}</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                <Button variant="primary menu-btn" onClick={handleShow}>
                  <img src={HamburgerIcon} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="headMarquee">
        <marquee scrollamount="3">
          <span className="alertMarq">‼️ALERT‼️</span>
          DO NOT PLAY‼️ 12:00 - 12:15 UTC Maintenance Time
        </marquee>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="sidebarafterlogin"
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                <ProfileIcon />
              </div>
              <div className="user-info">
                <span>{user?.mstruserid}</span>
                <a href="tel:{user?.mobileno}">{user?.mobileno}</a>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-6">
              <div className="balance mb-1">Main Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-1">
                {numberWithCommas(wallet?.cash || 0)}
              </div>
            </div>
            <div className="col-6">
              <div className="balance ">Bonus</div>
            </div>
            <div className="col-6">
              <div className="balance-amount ">
                {numberWithCommas(wallet?.coins) || 0}
              </div>
            </div>
          </div>
          <ul className="menu-items">
            <li className="d-block d-md-none">
              <a
                className={activePage === "/betby" ? "active" : ""}
                href="/betby"
              >
                <img src={LiveMatch} />
                <span>Live Betting</span>
              </a>
            </li>
            <li className="d-block d-md-none">
              <a
                className={activePage === "/betby" ? "active" : ""}
                href="/betby"
              >
                <img src={SportsBookFoot} />
                <span>Sportsbook</span>
              </a>
            </li>
            <li className="d-block d-md-none">
              <a
                className={activePage === "/Slots" ? "active" : ""}
                href="/Slots"
              >
                <img src={SlotsFoot} />
                <span>Slots</span>
              </a>
            </li>
            <li className="d-block d-md-none">
              <a
                className={activePage === "/Casino" ? "active" : ""}
                href="/Casino"
              >
                <img src={FoootCasino} />
                <span>Casino</span>
              </a>
            </li>

            <li>
              <a href="/profile">
                <ProfileIcon />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                <BetIcon />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <StatementIcon />
                <span>Account Statement</span>
              </a>
            </li>
            <li>
              <a href="/bonus">
                <img src={BonusImg} alt="BonusImg" />
                <span>Bonus</span>
              </a>
            </li>
            <li>
              <a href="/referral">
                <img src={ReferralImg} alt="BonusImg" />
                <span>Referral</span>
              </a>
            </li>

            <li onClick={() => dispatch(logoutUser())}>
              <a href="/">
                <LogoutIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links d-none">
            <li>
              <a target="_blank" href={`${appDetails?.TELEGRAM}`}>
                <TelegramIcon />
              </a>
            </li>
            {/* <li>
              <div
                onClick={() =>
                  window.open(https://wa.me/+917888846060, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <WhatsappIcon />
              </a>
            </li>
            <li className="theme-btn">
              <button
                type="button"
                onClick={() => setShowLightModeToggle((s) => !s)}
              >
                <div className="theme-icon">
                  <img src={MoonLight} alt="Moon Icon" className="dark_theme" />
                  <img src={SunLight} alt="Sun Icon" className="light_theme" />
                </div>
              </button>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        className="betslip_popup outer_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exposure amount</th>
                    <th scope="col">Match name</th>
                    <th scope="col">Ref match ID</th>
                    <th scope="col">Total bets</th>
                  </tr>
                </thead>
                <tbody>
                  {betMatches &&
                    betMatches.length &&
                    betMatches.map((item, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(item);
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{item?.exposureAmount}</td>
                          <td>{item?.matchName}</td>
                          <td>{item?.refMatchId}</td>
                          <td>{item?.totalBets}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="betslip_popup inner_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showUserBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Odds</th>
                    <th scope="col">Stack</th>
                    <th scope="col">Selection type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Create date</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets &&
                    userBets.length &&
                    userBets.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.odds}</td>
                          <td>{item?.stack}</td>
                          <td>{item?.selectionType}</td>
                          <td>{item?.status}</td>
                          <td>{item?.createdAt}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default HeaderAfterLogin;
